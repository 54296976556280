import { urls as itemsUrls } from 'Reducers/items';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { APIVERSION } from 'Constants/items';

const getItem = itemId => (dispatch, getState, { get }) => {
    const url = itemsUrls.getItem(itemId);

    return dispatch(get(url, 'ELEMENT_ITEMS'));
};

export const BXP_REQUIRED_HEADER = { 'x-panamera-client-id': `web-${PLATFORM}` };
const getItemDetail = (itemId, version = APIVERSION.V2, lang = null) => (dispatch, getState, { get, config }) => {
    const url = itemsUrls.getItemDetail(itemId, version);
    const params = lang ? { lang } : {};
    const opts = {
        shouldByPassProxy: true,
        config
    };

    return dispatch(get(url, 'ELEMENT_ITEMS', params, BXP_REQUIRED_HEADER, opts));
};

export {
    getItem,
    getItemDetail
};
