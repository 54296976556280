/* global */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './MenuHeader.scss';
import classnames from 'classnames';
import AsyncMyAccount from 'Components/MyAccount/AsyncMyAccount';
import AsyncMyAccountMxCl from 'Components/MyAccount/MyAccountMxCl/AsyncMyAccountMxCl';
import { withConfig } from 'HOCs/withConfig/withConfig';

const WAIT_HIDE = 500;

export class MenuHeader extends Component {
    static propTypes = {
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        open: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        toggleLangModal: PropTypes.func
    }

    static defaultProps = {
        open: false,
        toggleLangModal: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        const { marketConfig } = props;
        const olxAutosConfig = marketConfig.get('olxAutos');

        this.isMxCl = !!(olxAutosConfig && olxAutosConfig.isMXCL);
        this.isOtoplus = props.marketConfig.get('theme', 'id') === 'otoplus';
    }

    componentDidMount() {
        this.timer = null;
    }

    componentDidUpdate(prevProps) {
        clearTimeout(this.timer);
        if (this.props.open !== prevProps.open) {
            const visible = this.props.open;

            if (visible) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ visible });
            }
            else {
                this.timer = setTimeout(() => this.setState({ visible }), WAIT_HIDE);
            }
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer);
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {
        const { open, toggleLangModal } = this.props;
        const menuHeaderClass = classnames(css.menuHeader, open ? css.open : css.close, this.isMxCl ? css.olxAutoHeader : '');

        return (
            <div className={ menuHeaderClass }>
                { this.state.visible
                    && <div className={ css.scrollSection }>
                        {this.isMxCl
                            ? <AsyncMyAccountMxCl onClose={ this.handleClose } isOtoplus={ this.isOtoplus } />
                            : <AsyncMyAccount onClose={ this.handleClose } toggleLangModal={ toggleLangModal } selectFrom="header" />
                        }
                    </div>
                }
            </div>
        );
    }
}

export default withConfig('marketConfig')(MenuHeader);
