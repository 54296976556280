import { compose } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withRouter from 'HOCs/withRouter';
import withTags from 'HOCs/withTags/withTags';
import { routerParamsToSeoQuery } from 'Helpers/listing';
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import css from './Footer.scss';
import { ShowMore } from 'panamera-react-ui';
import { FormattedMessage as Translation } from 'react-intl';

export const SeoText = ({ seoText }) => {
    if (seoText) {
        const translations = {
            viewMore: <Translation id="viewMore" />,
            viewLess: <Translation id="viewLess" />
        };

        return (
            <ExpansionPanel
                title={ seoText.title }
                childrenClassName={ css.nestedChildrenSEOText }
                containerClassName={ classNames(css.expansionPanel, css.nested) }
                icon="arrowRight" >
                <ShowMore
                    placeholder={ seoText.text }
                    more={ translations.viewMore }
                    less={ translations.viewLess }
                    markdown={ true } />
            </ExpansionPanel>
        );
    }
    return null;
};

SeoText.propTypes = {
    seoText: PropTypes.exact({
        title: PropTypes.string,
        text: PropTypes.string
    })
};

export default compose(
    withRouter,
    withTags('listing', routerParamsToSeoQuery, { withHelmet: false })
)(SeoText);
