import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './OnBoardingArrow.scss';

const OnBoardingArrow = ({ afterMiddle }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={ classnames(css.arrow, afterMiddle && css.arrowAfterMiddle) } width="93" height="58" viewBox="0 0 93 58">
        <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round" strokeWidth="4">
            <path d="M2 55.495s25-49.5 86.5-36.5M73.304 2.82l15.89 16.884L70.01 30.82" />
        </g>
    </svg>
);

OnBoardingArrow.propTypes = {
    afterMiddle: PropTypes.bool.isRequired
};

export default OnBoardingArrow;
