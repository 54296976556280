const getUserFromProp = props => {
    const { router: { params = {}} = {}} = props || {};

    return (params && params.userId) ? params.userId : '';
};

const getReviewIdsFromProp = props => {
    const { location: { query = {}} = {}} = props || {};
    const buyerId = (query && query.buyer_id) ? query.buyer_id : '';
    const sellerId = (query && query.seller_id) ? query.seller_id : '';
    const adId = (query && query.ad_id) ? query.ad_id : '';

    return { buyerId, sellerId, adId };
};

const getLoginMethod = userName => {
    if (userName.search('@') > 0) {
        return 'email';
    }

    return 'phone';
};

export {
    getUserFromProp,
    getLoginMethod,
    getReviewIdsFromProp
};
