import React from 'react';
import PropTypes from 'prop-types';
import { Textarea as ReactUITextarea } from 'panamera-react-ui';
import withConfig from 'HOCs/withConfig/withConfig';
import { THEMES } from 'Constants/bundles';

export const Textarea = ({ config, ...restProps }) => {
    const id = config?.get?.('theme')?.id;

    return (
        <ReactUITextarea { ...restProps } roundedInputPixel={ id === THEMES.LETGO } />
    );
};

Textarea.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func
    }).isRequired
};

export default withConfig(Textarea);
