import React, { Suspense } from 'react';
import { FullPageLoader } from 'panamera-react-ui';

const AsyncOverlaySearchComponent = React.lazy(() => import(/* webpackChunkName: "overlaySearchMX" */'./OverlaySearchMX'));
const loader = <FullPageLoader />;

const AsyncOverlaySearch = props => (
    <Suspense fallback={ loader }>
        <AsyncOverlaySearchComponent { ...props } />
    </Suspense>
);

export default AsyncOverlaySearch;
