/* eslint-disable react/jsx-no-bind */

import React, { Component } from 'react';
import css from './IconMenu.scss';
import cssLamudi from 'Components/LamudiBranding/LamudiBranding.scss';
import classnames from 'classnames';
import LamudiBranding from 'Components/LamudiBranding/LamudiBranding';
import { Link, DropdownMenu } from 'panamera-react-ui';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withTrack from 'HOCs/withTrack/withTrack';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { ImageWrapper } from 'Components/ImageWrapper/ImageWrapper';
import { SVG_TYPE } from 'Constants/images';
import withRouter from 'HOCs/withRouter';
import CookieManager from 'Helpers/cookies';
import { TAB_NAME, USER_TAB_COOKIE } from 'Constants/buyLandingPage';
import { CONFIG } from 'Constants/config';
import SITE_CODES from 'Constants/siteCodes';
import { getLogoData } from '../../logoSizeHelper';
import { UserSession, EvaluationConstants } from 'olx-autos-landing-page';
import { showLamudiBranding } from 'Helpers/lamudiHelper';
import { LAMUDI_LOGO } from 'Constants/imageUrls';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { FormattedMessage as Translation } from 'react-intl';
import { THEMES } from 'Constants/bundles';
import { renderModal, toggleReportModal } from 'Helpers/reportUser';
import ShareProfile from 'Components/Profile/ShareProfile/ShareProfile';
import { userIsLogged } from 'Selectors/user';
import { PUBLIC_PROFILE_ICONS, ICON_COLORS, ICONS } from 'Constants/items';
import { isCocoFofoUser } from 'Helpers/item';
import { getUserFromProp } from 'Helpers/users';
import { userSelector } from 'Selectors/users';
import { CHOOSE_PACKAGE } from 'Constants/translations';

export class IconMenu extends Component {
    static propTypes = {
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        router: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        showNotification: PropTypes.bool,
        openMenu: PropTypes.bool,
        track: PropTypes.func,
        headerLogoRef: PropTypes.object,
        hideHamburger: PropTypes.bool,
        isOlxAutosLandingPage: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        categoryId: PropTypes.string,
        isTransaction: PropTypes.bool,
        showProfileOptionsDropdown: PropTypes.bool,
        userId: PropTypes.string,
        userIsLogged: PropTypes.bool,
        user: PropTypes.object,
        experimentVariant: PropTypes.bool
    };

    static defaultProps = {
        showNotification: false,
        isOlxAutosLandingPage: false,
        categoryId: '',
        isTransaction: false,
        showProfileOptionsDropdown: false,
        user: {}
    }

    constructor(props) {
        super(props);
        const { marketConfig } = props;
        const olxAutosConfig = marketConfig.get('olxAutos');

        this.otoplusConfig = marketConfig.get('theme', 'id') === 'otoplus';

        this.isMxCl = !!(olxAutosConfig && olxAutosConfig.isMXCL);

        this.host = marketConfig.get(CONFIG.HOST);

        this.state = {
            openReportModal: false
        };
        this.isNotificationsRevamp = !marketConfig.get('notifications', 'isDesignRevamp');
    }

    handleClick = () => {
        const { openMenu } = this.props;
        const trackMethod = openMenu ? 'ham_close' : 'ham_open';

        this.props.track(trackMethod);
        return this.props.onClick();
    }

    handleTrack = () => this.props.track('explore_tap', { select_from: 'logo' });

    render() {
        const { router, showNotification, openMenu, marketConfig, headerLogoRef, hideHamburger, isOlxAutosLandingPage, categoryId, isPaidCampaign, isTransaction, showProfileOptionsDropdown, userId, user, experimentVariant } = this.props;
        const path = router.location.pathname;
        const LogoTag = (path.length > 1) ? 'div' : 'h1';
        const tab = CookieManager.readCookie(USER_TAB_COOKIE);
        const sitecode = marketConfig.get('siteCode');
        const isIDBuyLanding = isOlxAutosLandingPage && tab === TAB_NAME.BUY && sitecode === SITE_CODES.OLX_ID;
        const isHandleSeoLink = marketConfig.get('olxAutos', 'isHandleSeoLink');
        const buyLandingPage = marketConfig.get('landingPageRoutes', 'buy-organic');
        const isOlxIDCarListingPage = categoryId === marketConfig.get('olxAutos', 'carCategory') && marketConfig.get('showOlxAutosLogoOnCarCategory');
        const theme = marketConfig.get('theme', 'id');
        const enableNewIndonesiaBranding = marketConfig.get('enableNewIndonesiaBranding');
        const showAutosLogo = this.isMxCl || isOlxAutosLandingPage || isOlxIDCarListingPage || theme === THEMES.LETGO;
        const staticAssetPath = marketConfig.get('staticAssets') ? marketConfig.get('staticAssets') : '';
        const { logoUrl, logoClass, logoWidth, logoHeight } = getLogoData(theme, showAutosLogo, staticAssetPath, enableNewIndonesiaBranding);
        const logoAltText = marketConfig.get('logoAltText') ?? '';
        const logoRedirectionUrl = marketConfig.get('lamudiBranding', 'logoRedirectionUrl');

        const { EVALUATION_STATES } = EvaluationConstants;
        const predictedPrice = UserSession.getEvaluationPredictedPrice() || {};
        const isPricePredicted = predictedPrice?.predictions?.list?.length;
        const evalState = UserSession.getUserEvaluationState();
        const isevalCallback = evalState === EVALUATION_STATES.CALLBACK_REQUESTED;
        const cocoFofoUser = isCocoFofoUser(user);

        const iconSize = 24;
        let routeLink;

        if ((isevalCallback && isPricePredicted && isPaidCampaign)) {
            routeLink = '/sell-car';
        }
        else if ((isevalCallback && isPaidCampaign) || (isevalCallback && !isPaidCampaign && !isPricePredicted)) {
            routeLink = isHandleSeoLink ? '/valuation-flow/sell' : '/valuation-flow';
        }
        else {
            routeLink = '/';
        }

        const onClose = () => {
            router.goBack();
        };

        const handleCallbackRoute = () => {
            if (isevalCallback) {
                UserSession.setUserEvaluationState(EVALUATION_STATES.INITIAL);
                UserSession.deleteEvaluationFormValues();
                UserSession.deleteLastSelectionOption();
            }
            else if (isPricePredicted) {
                UserSession.setUserEvaluationState(EVALUATION_STATES.VALUATION_DONE);
            }
            else {
                UserSession.setUserEvaluationState(EVALUATION_STATES.INITIAL);
            }
        };

        const logoIcon = showAutosLogo || enableNewIndonesiaBranding ? (
            <Link title={ logoAltText ?? 'logo' }
                to={ isIDBuyLanding || isOlxIDCarListingPage ? `${buyLandingPage}` : routeLink }
                onClick={ handleCallbackRoute }
            >
                <ImageWrapper
                    config={ marketConfig }
                    fileName={ logoUrl }
                    types={ SVG_TYPE }
                    alt={ logoAltText }
                    width={ logoWidth }
                    height={ logoHeight }
                    className={ css[logoClass] }
                />
            </Link>
        ) : null;

        const getDropdownMenuOptions = () => {
            const dropdownOptions = [
                <ShareProfile
                    userId={ userId }
                    textId={ 'shareProfile' }
                    mobileHeaderLink={ true }
                    key={ 1 }
                />
            ];

            if (this.props.userIsLogged && !cocoFofoUser) {
                dropdownOptions.unshift(
                    <div key={ 0 }
                        data-aut-id="reportUser"
                        className={ css.chatOptionsItem }
                        onClick={ () => toggleReportModal(this, userId) }
                    >
                        <Translation id="reportUserModalTitle" />
                    </div>
                );
            }

            const elements = {
                dropdownButton: (
                    <span ref={ dropdownButton => (this.dropdownButton = dropdownButton) }>
                        <Icon icon={ PUBLIC_PROFILE_ICONS.MORE_VERTICAL } color={ ICON_COLORS.BLACK_SECONDARY_TEXT } size={ iconSize } />
                    </span>
                ),
                dropdownOptions
            };

            return elements;
        };

        return (
            <div data-aut-id="btnMenu"
                className={ classnames(css.iconMenu, {
                    [css.ppcLogo]: hideHamburger
                }) }>
                {!experimentVariant && !hideHamburger && <span
                    className={ classnames(
                        { [css.notification]: showNotification && this.isNotificationsRevamp && !openMenu },
                        isTransaction ? css.transactionNotification : css.classifiedNotification) }
                >
                    <NewIconButton
                        icon={ openMenu ? 'cross' : 'menu' }
                        onClick={ this.handleClick }
                        animation={ !this.otoplusConfig }
                    />
                </span>}
                {!experimentVariant && <LogoTag ref={ headerLogoRef }
                    className={ classnames(css.logo, {
                        [css.disableClick]: (isOlxAutosLandingPage && hideHamburger && evalState !== EVALUATION_STATES.CALLBACK_REQUESTED) || (isPaidCampaign && evalState !== EVALUATION_STATES.CALLBACK_REQUESTED)
                    }) }>
                    {logoIcon || <Link title="olx" to="/" data-aut-id="btnOlxLogo" onClick={ this.handleTrack }>
                        <Icon
                            icon="olx"
                            size={ 38 }
                        />
                    </Link>}
                </LogoTag>}

                {experimentVariant && <div className={ css.headerFA }>
                    <NewIconButton
                        onClick={ onClose }
                        icon={ ICONS.BACK }
                        color={ ICON_COLORS.PRIMARY_BASE }
                        size={ 20 }
                    />
                    <div className={ css.headerCrossIcon }>
                        <Translation id={ CHOOSE_PACKAGE.SELL_FASTER } />
                    </div>
                </div>}

                { showLamudiBranding(marketConfig, categoryId)
                    && (<LamudiBranding assetUrl={ LAMUDI_LOGO } redirectionUrl={ logoRedirectionUrl } cssClass={ cssLamudi.headerLogo } />) }

                {showProfileOptionsDropdown
                    && <div className={ css.chatListHeaderOptions } >
                        <DropdownMenu
                            actionable={ getDropdownMenuOptions().dropdownButton }
                            className={ css.chatListHeaderOptionsItem }
                            listClassName={ css.chatListHeaderDropdownList }
                            onClick={ this.openDropDown }
                        >
                            { getDropdownMenuOptions().dropdownOptions }
                        </DropdownMenu>
                        { renderModal(this, userId) }
                    </div>
                }
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const userId = getUserFromProp(ownProps);

    return {
        showNotification: state.toggleUnreadNotifications || state.toggleUnreadChats,
        isTransaction: state.features?.feature_flags?.transaction_inbox,
        userIsLogged: userIsLogged(state),
        user: userSelector(state, userId)
    };
};

export default compose(
    withConfig('marketConfig'),
    withRouter,
    withTrack,
    connect(mapStateToProps)
)(IconMenu);
