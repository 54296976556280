/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './MultiLangPickerMobile.scss';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import { withConfig } from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { setOrigin } from 'Actions/track';
import classNames from 'classnames';
import cookieManagerHelper from 'Helpers/cookies';
import withRouter from 'HOCs/withRouter';
import { getSelectedLang } from 'Selectors/getLanguages';
import withDirection from 'HOCs/withDirection/withDirection';
import AsyncLanguageList from '../LanguageList/AsyncLanguageList';

export class MultiLangPickerMobile extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        selectedLanguage: PropTypes.string,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        getSelectedLang: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
        onClose: PropTypes.func,
        track: PropTypes.func.isRequired,
        trackerOrigins: PropTypes.object.isRequired,
        setOriginTrack: PropTypes.func.isRequired
    };

    static defaultProps = {
        className: '',
        open: false,
        onClose: () => {}
    };

    constructor(props) {
        super(props);
        this.langSupp = props.marketConfig.get('langs');
        this.state = {
            selectLang: props.getSelectedLang,
            visible: props.open
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            const visible = this.props.open;

            if (visible) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ visible });
                this.props.track('language_update_show');
            }
        }
    }
    renderLanguageList = () => {
        const { selectLang } = this.state;

        return (
            <AsyncLanguageList
                className={ css.dropdownItems }
                onSelect={ this.onSelect }
                selectLang={ selectLang }
                langSupp={ this.langSupp }
            />
        );
    }

    onSelect = lang => {
        this.setState({ selectLang: lang });
        const origin = this.props.trackerOrigins.HEADER;
        const trackingParam = {
            origin,
            chosen_option: lang
        };

        this.props.setOriginTrack(origin);
        this.props.track('language_updated', trackingParam);

        cookieManagerHelper.createCookie('lang', lang);
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    }

    getOnboardingLanguageConfig(lang) {
        const langs = this.props.marketConfig.get('langs');

        return langs.find(({ param }) => param === lang);
    }

    render() {
        const { open, direction, onClose } = this.props;
        const modalClass = classNames(css.modalClass, open ? css.open : css.close);
        const langConfig = this.getOnboardingLanguageConfig(this.props.selectedLanguage);

        return (
            <div className={ modalClass }>
                { this.state.visible
                    && <div className={ css.scrollSection }>
                        <div className={ css.headerContainer }>
                            <span className={ css.okIcon }>
                                <NewIconButton
                                    icon="back"
                                    color="blackText"
                                    animation={ false }
                                    direction={ direction }
                                    onClick={ onClose }
                                />
                            </span>
                            <p className={ css.navBarTitle }>{ langConfig.languageSelectMenu }</p>
                        </div>

                        { this.renderLanguageList() }
                    </div>
                }
            </div>
        );
    }
}

export const mapDispatchToProps = dispatch => {
    return {
        setOriginTrack: origin => dispatch(setOrigin(origin))
    };
};

export const mapStateToProps = (state, ownProps) => {
    return {
        getSelectedLang: getSelectedLang(state, ownProps),
        selectedLanguage: state.api && state.api.queryParam && state.api.queryParam.lang
    };
};

export default compose(
    withConfig('marketConfig'),
    withTrack,
    withRouter,
    withDirection,
    connect(mapStateToProps, mapDispatchToProps)
)(MultiLangPickerMobile);
