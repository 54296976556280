import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import CollapsableList from '../CollapsableList';
import { MAX_POPULAR_SEARCHES } from 'Constants/popularSearches';
import withSearches from 'HOCs/withSearches/withSearches';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import withTrack from 'HOCs/withTrack/withTrack';
import { POPULAR_SEARCH, SEARCH_TYPE } from 'Constants/tracking';

export const RelatedSearches = ({ searches, track, ...props }) => {
    const listLinks = searches.slice(0, MAX_POPULAR_SEARCHES);

    function trackPopularSearch(element) {
        track(POPULAR_SEARCH, { chosen_option: element.term });
        setSessionStorageItem('search_type', SEARCH_TYPE.POPULAR_SEARCH);
    }

    return (
        <CollapsableList
            { ...props }
            listLinks={ listLinks }
            track={ trackPopularSearch }
        />
    );
};

RelatedSearches.propTypes = {
    searches: PropTypes.array,
    track: PropTypes.func
};

RelatedSearches.defaultProps = {
    searches: []
};

export default compose(
    withSearches,
    withTrack
)(RelatedSearches);
