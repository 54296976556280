import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import toggleFilters from 'Actions/toggleFiltersTanak';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import { getAppliedFiltersLength, getHiddenFilterIds } from 'Selectors/filtersTanak';
import css from './FilterIcon.scss';
import withTrack from 'HOCs/withTrack/withTrack';
import { LISTING_TAP_SELECT_FILTERS, SELECT_FROM } from 'Constants/tracking';

export class FilterIcon extends React.Component {
    static propTypes = {
        toggleFilters: PropTypes.func.isRequired,
        appliedFiltersLength: PropTypes.number,
        openModal: PropTypes.func.isRequired,
        containsOverlayHash: PropTypes.func.isRequired,
        track: PropTypes.func,
        hiddenFilterIds: PropTypes.object,
        isOlxAutos: PropTypes.bool
    }

    onClick = () => {
        this.props.track?.(LISTING_TAP_SELECT_FILTERS, {
            select_from: SELECT_FROM.FILTER_PAGE
        });
        this.props.toggleFilters();
        this.props.openModal();
    }

    render() {
        const hiddenFiltersAppliedCount = Object.keys(this.props.hiddenFilterIds).length;
        const appliedFiltersCount = this.props.appliedFiltersLength - hiddenFiltersAppliedCount;

        return (
            <div className={ classnames(css.filterIconContainer, { [css.autosFilterIcon]: this.props.isOlxAutos }) } onClick={ this.onClick }>
                <div className={ css.filter }>
                    <NewIconButton
                        icon={ 'filters' }
                        size={ 22 }
                        animation={ this.props.containsOverlayHash('#filter') }
                        data-aut-id="filterIcon"
                    />
                </div>
                { appliedFiltersCount > 0 && <div className={ css.oval }>
                    <div className={ css.number }>{ appliedFiltersCount }</div>
                </div>
                }
            </div >
        );
    }
}

export const mapStateToProps = state => ({
    appliedFiltersLength: getAppliedFiltersLength(state),
    hiddenFilterIds: getHiddenFilterIds(state)
});

export const mapDispatchToProps = dispatch => ({
    toggleFilters: state => dispatch(toggleFilters(state))
});

export default compose(
    withTrack,
    withBackButtonHandling('#filter'),
    connect(mapStateToProps, mapDispatchToProps)
)(FilterIcon);
