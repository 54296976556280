import React from 'react';
import ReportModal from 'Components/ReportModal/ReportModal';

const toggleReportModal = (thisComponent, userId) => {
    thisComponent.setState({
        openReportModal: !thisComponent.state.openReportModal
    }, () => {
        if (thisComponent.state.openReportModal) {
            thisComponent.props.track('trust_tap_report_user_start', {
                user_affected: userId
            });
        }
    });
};

const renderModal = (thisComponent, userId) => {
    function closeModal() {
        thisComponent.setState({
            openReportModal: !thisComponent.state.openReportModal
        });
    }

    return (
        thisComponent.state.openReportModal
        && <ReportModal ID={ userId } onClick={ closeModal } type="users" data-aut-id="reportModal" />
    );
};

export {
    toggleReportModal,
    renderModal
};
