/* window */
import React from 'react';
import PropTypes from 'prop-types';
import css from './PreNotification.APP_TARGET.scss';
import { css as uiCss } from 'panamera-react-ui';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withVariants } from 'LaquesisEOL/withVariants';
import { preNotificationAvailable, preNotificationCookie } from 'Actions/preNotification';
import { FormattedMessage as Translation } from 'react-intl';
import * as permissionsHelper from 'Helpers/pushNotification';
import withTrack from 'HOCs/withTrack/withTrack';
import { setPreNotificationCookie } from './PreNotification';
import classNames from 'classnames';
import { removeGcmSubscription } from 'Helpers/pushNotification';

const { icons } = uiCss;

export class PreNotification extends React.Component {
    static propTypes = {
        track: PropTypes.func.isRequired,
        actionPreNotificationAvailable: PropTypes.func,
        actionPreNotificationCookie: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.preNotificationTitle = <Translation id="preNotificationMobileTitle" />;
        this.preNotificationLink = <Translation id="preNotificationMobileLink" />;
        this.preNotificationText = <Translation id="preNotificationMobileText" />;
        this.state = {
            animations: css.animateDown
        };
    }

    accept = () => {
        const { track } = this.props;

        permissionsHelper.askForPermissionToReceiveNotifications(track)
            .then(this.close());
    };

    close = () => {
        const { actionPreNotificationAvailable, actionPreNotificationCookie } = this.props;

        this.setState({ animations: css.animateUp });
        actionPreNotificationAvailable(false);
        setPreNotificationCookie();
        actionPreNotificationCookie(true);
        removeGcmSubscription();
    };

    render() {
        const { animations } = this.state;

        return (
            <div className={ classNames(css.content, animations) } >
                <div className={ css.up }>
                    <div className={ css.icon }>
                        <div className={ css.rectangle }>
                            <div className={ classNames(icons['icon-OLX'],
                                icons.panameraIcons, css.mask) } />
                        </div>
                    </div>
                    <div className={ css.description }>
                        <div className={ css.notificationTitle }>{ this.preNotificationTitle }<br /></div>
                        <div className={ css.notificationText }>
                            { this.preNotificationText }
                        </div>
                    </div>
                    <div className={ css.x } onClick={ this.close }>
                        <span className={ classNames(css.fill, icons.panameraIcons, icons['icon-Cross']) } />
                    </div>
                </div>
                <div className={ css.link }>
                    <div className={ css.text }
                        onClick={ this.accept }>+ { this.preNotificationLink }</div>
                </div>
            </div>
        );
    }
}

export const mapDispatchToProps = ({
    actionPreNotificationAvailable: preNotificationAvailable,
    actionPreNotificationCookie: preNotificationCookie
});

export default withVariants('PAN-28778', {
    b: compose(
        withTrack,
        connect(null, mapDispatchToProps)
    )(PreNotification),
    'else': () => {
        return null;
    }
});
