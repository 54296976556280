import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as Translation } from 'react-intl';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { getLoggedUser } from 'Selectors/user';
import CopyToClipboard from 'Components/CopyToClipboard/CopyToClipboard';
import Button from 'Components/Button/Button';
import css from './ShareProfile.scss';
import { BLUELINK_BTN, PRIMARY_BTN, TERTIARY_BTN } from 'Constants/buttons';
import { SELECT_FROM_MY_PROFILE, SELECT_FROM_OTHER_PROFILE, SOCIAL_PROFILE_SHARE } from 'Constants/tracking';

// eslint-disable-next-line react/prefer-stateless-function
export class ShareProfile extends React.PureComponent {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        size: PropTypes.string,
        fixed: PropTypes.bool,
        isLink: PropTypes.bool,
        profileLink: PropTypes.string,
        onClickProfile: PropTypes.func,
        textId: PropTypes.string,
        isLinkCenter: PropTypes.bool,
        isBtnBorderBottom: PropTypes.bool,
        isClassified: PropTypes.bool,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        showMXBtn: PropTypes.bool,
        mobileHeaderLink: PropTypes.bool,
        track: PropTypes.func.isRequired,
        loggedUser: PropTypes.object,
        origin: PropTypes.string,
        type: PropTypes.string
    };

    static defaultProps = {
        size: 'small',
        fixed: true,
        isLink: false,
        isClassified: false,
        profileLink: '',
        onClickProfile: () => {},
        textId: 'shareProfile',
        isLinkCenter: true,
        isBtnBorderBottom: false,
        showMXBtn: false,
        mobileHeaderLink: false,
        origin: null
    };

    constructor(props) {
        super(props);
        this.host = props.config.get('host');
        this.showNewDesign = props.config.get('adpvAuto', 'showMXDesign')
            || props.config.get('adpvAuto', 'showNewDesign');
        this.showMXBtn = this.showNewDesign || props.showMXBtn;
        this.isMXCL = props.config.get('olxAutos', 'isMXCL');
        this.hostOLXmobbi = props.config.get('hostOLXmobbi');
    }

    getButton(stylingDiv, stylingBtn, type, btnSize, fixed, centerBtn, onClickProfile) {
        const { profileLink, textId, showMXBtn, isClassified, mobileHeaderLink, loggedUser, userId, origin } = this.props;
        const isMxcl = this.props.config.get('adpvAuto', 'showMXDesign');

        this.onButtonClick = () => {
            onClickProfile();
            this.props.track(SOCIAL_PROFILE_SHARE, {
                select_from: (loggedUser?.id === userId) ? SELECT_FROM_MY_PROFILE : SELECT_FROM_OTHER_PROFILE,
                origin
            });
        };

        return (
            <div className={ classNames(centerBtn, stylingDiv) } >
                <Button
                    href={ isMxcl ? `/olxmobbi${profileLink}` : profileLink }
                    className={ stylingBtn }
                    type={ this.props?.type || type }
                    fixed={ !this.showNewDesign && !showMXBtn && fixed }
                    size={ btnSize }
                    onClick={ this.onButtonClick }
                    data-aut-id={ textId }
                    icon={ isClassified ? 'shareProfile' : null }
                >
                    <span className={ mobileHeaderLink ? css.mobileHeaderLinkSpan : '' }><Translation id={ textId } /></span>
                </Button>
            </div>
        );
    }

    render() {
        const { userId, size, fixed, isLink, onClickProfile, isLinkCenter, isBtnBorderBottom, isClassified, mobileHeaderLink, textId } = this.props;
        const profileUrl = `${ this.isMXCL ? `${this.hostOLXmobbi}/olxmobbi` : this.host }/profile/${ userId }`;
        let stylingDiv = css.shareProfileLink;
        let stylingBtn = '';
        let type = BLUELINK_BTN;
        let btnSize = size;
        let centerBtn = '';

        if (this.showMXBtn) {
            stylingDiv = isClassified ? css.modernShareProfileLink : css.shareProfileLinkMX;
            stylingBtn = (isClassified) ? css.modernShareProfileLinkBtn : ((textId != 'profileLinkTxt') && css.shareProfileLinkMXBtn);
            type = isClassified ? PRIMARY_BTN : TERTIARY_BTN;
            btnSize = 'small';
            stylingDiv = mobileHeaderLink ? css.mobileHeaderDivStyle : stylingDiv;
            stylingBtn = mobileHeaderLink ? css.mobileHeaderBtnStyle : stylingBtn;

            if (isLinkCenter) {
                centerBtn = css.shiftLinkCenter;
            }
            else if (isBtnBorderBottom) {
                centerBtn = css.btnBorderBottom;
            }
        }

        return (
            <React.Fragment>
                {
                    isLink ? this.getButton(stylingDiv, stylingBtn, type, btnSize, fixed, centerBtn, onClickProfile)
                        : <CopyToClipboard
                            value={ profileUrl }
                        >
                            {({
                                onCopyToClipboard
                            }) => (
                                this.getButton(stylingDiv, stylingBtn, type, btnSize, fixed, centerBtn, onCopyToClipboard)
                            )}
                        </CopyToClipboard>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = state => {
    return {
        loggedUser: getLoggedUser(state)
    };
};

export default compose(
    withTrack,
    withConfig,
    connect(mapStateToProps)
)(ShareProfile);
