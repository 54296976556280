import React from 'react';
import css from './SearchHeader.mobile.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchInput from './components/SearchInput';
import IconMenu from './components/IconMenu';
import { FormattedMessage as Translation } from 'react-intl';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import LocationMenu from './components/LocationMenu';
import MenuHeader from './components/MenuHeader';
import FilterIcon from './components/FilterIcon';
import Auth from '../../../../Auth';
import OnBoarding from 'Components/OnBoarding/OnBoarding';
import cookieManagerHelper from 'Helpers/cookies';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import withConfig from 'HOCs/withConfig/withConfig';
import PreNotification from 'Components/PreNotification/PreNotification.mobile';
import MultiLangPickerMobile from 'Components/HeaderWrapper/components/MultiLangPickerMobile/MultiLangPickerMobile';
import CompareIconCTA from 'Components/Compare/CompareIconCTA/CompareIconCTA';
import FavouriteCTA from 'Components/FavouriteCTA/FavouriteCTA';
import { userIsLogged } from 'Selectors/user';
import { FAVOURITE, LQ_SHORTLIST_FEATURE } from 'Constants/items';
import { THEMES } from 'Constants/bundles';
import {
    __FEATURE_AD_UPGRADE
} from 'Constants/monetizers';
import { VARIANTS } from 'Constants/constants';
import { CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG } from 'Helpers/laquesis';
import { withExperiment } from 'LaquesisEOL/withExperiment';
import { Link } from 'panamera-react-ui';
import { unreadNotificationsSelector } from 'Selectors/notifications';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

const HIDE_ONBOARDING_LOCATION = 'HIDE_ONBOARDING_LOCATION';
const COOKIE_EXPIRES_DAYS = 1825;

export class SearchHeader extends React.Component {
    static propTypes = {
        enableLocationMenu: PropTypes.bool,
        enableSearchInput: PropTypes.bool,
        params: PropTypes.object,
        enableFiltersMenu: PropTypes.bool,
        router: PropTypes.object,
        preNotificationAvailable: PropTypes.bool,
        preNotificationCookie: PropTypes.bool,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        headerClass: PropTypes.string,
        withSubheader: PropTypes.bool,
        headerLogoRef: PropTypes.object,
        headerRef: PropTypes.object,
        hideHamburger: PropTypes.bool,
        isOlxAutosLandingPage: PropTypes.bool,
        isPaidCampaign: PropTypes.bool,
        categoryId: PropTypes.string,
        seller_state: PropTypes.string,
        flowStep: PropTypes.string,
        userIsLogged: PropTypes.bool,
        variant: PropTypes.string,
        featureFlag: PropTypes.string,
        showProfileOptionsDropdown: PropTypes.bool,
        userId: PropTypes.string,
        user: PropTypes.object,
        unreadNotifications: PropTypes.bool
    };

    static defaultProps = {
        enableLocationMenu: false,
        enableSearchInput: true,
        enableFiltersMenu: false,
        headerClass: '',
        params: {},
        isOlxAutosLandingPage: false,
        categoryId: '',
        userIsLogged: false,
        featureFlag: '',
        showProfileOptionsDropdown: false,
        user: {},
        variant: ''
    };

    constructor(props) {
        super(props);
        const { config } = this.props;

        this.siteCode = config.get('siteCode');
        this.state = {
            openMenu: false,
            openLangModal: false
        };
        this.isNotificationsRevamp = !!config.get('notifications', 'isDesignRevamp');
    }

    handleToggleMenu = () => this.setState(prevState => ({ openMenu: !prevState.openMenu }));

    handleCloseOnboarding = () => {
        cookieManagerHelper.createCookie(HIDE_ONBOARDING_LOCATION, true, COOKIE_EXPIRES_DAYS);
        this.forceUpdate();
    }

    handleToggleLangModal = () => this.setState(prevState => ({ openLangModal: !prevState.openLangModal }));

    render() {
        const { enableLocationMenu, enableSearchInput, enableFiltersMenu,
            router, preNotificationAvailable, preNotificationCookie, config,
            headerClass, withSubheader, headerLogoRef, headerRef, hideHamburger,
            isPaidCampaign, categoryId, isOlxAutosLandingPage, seller_state, flowStep,
            userIsLogged, featureFlag, showProfileOptionsDropdown, userId, user, params, variant, unreadNotifications } = this.props;
        const { openMenu, openLangModal } = this.state;
        const multiLangFlag = config.get('enableMultiLang');
        const onBoardingText = <Translation id="onBoardingLocationMessage" />;
        const onBoardingTitle = <Translation id="onBoardingLocationTitle" />;
        const isCarCompareEnabled = config.get('olxAutos', 'isCarCompareEnabled');
        const isCtxMarket = config.get('olxAutos', 'isMXCL');
        const theme = config.get('theme', 'id');
        const isLetGoTheme = theme === THEMES.LETGO;

        const experimentVariant = params.featureType === __FEATURE_AD_UPGRADE && (variant === VARIANTS.VARIANT_B || variant === VARIANTS.VARIANT_C);

        this.isMXCL = isCtxMarket || isOlxAutosLandingPage;

        const showCompareIcon = this.isMXCL && isCarCompareEnabled && flowStep;
        const showFavIcon = featureFlag === LQ_SHORTLIST_FEATURE && isCtxMarket && userIsLogged && !enableFiltersMenu && flowStep;

        return (
            <header className={ classNames(css.searchHeader, headerClass, {
                [css.withSubheader]: withSubheader,
                [css.shadow]: isPaidCampaign
            }) }
            data-aut-id="defaultHeader">
                {router.getCurrentLocation().pathname === '/'
                    && preNotificationAvailable
                    && !preNotificationCookie
                    && <PreNotification />
                }
                <div className={ classNames(css.headerContainer, { [css.headerContainerFA]: experimentVariant && !isLetGoTheme, [css.mxclHeaderContainer]: this.isMXCL }) } ref={ headerRef } >
                    <div className={ css.menu }>
                        <IconMenu experimentVariant={ experimentVariant } isOlxAutosLandingPage={ this.isMXCL } categoryId={ categoryId } onClick={ this.handleToggleMenu } openMenu={ openMenu } headerLogoRef={ headerLogoRef } hideHamburger={ hideHamburger } isPaidCampaign={ isPaidCampaign } seller_state={ seller_state } showProfileOptionsDropdown={ showProfileOptionsDropdown } userId={ userId } user={ user } />
                        {enableLocationMenu && !openMenu && (
                            <div
                                id="menuLocation"
                                className={ classNames(css.locationMenu, { [css.mxclLocationMenu]: this.isMXCL }) }
                                ref={ el => (this.elementNode = el) }
                            >
                                <LocationMenu isMXCL={ this.isMXCL } />
                                {cookieManagerHelper.isCookieEnabled() && !cookieManagerHelper.readCookie(HIDE_ONBOARDING_LOCATION) && (
                                    <OnBoarding
                                        elementNode={ this.elementNode }
                                        onClose={ this.handleCloseOnboarding }
                                        title={ onBoardingTitle }
                                        message={ onBoardingText } />
                                )}
                            </div>
                        )}
                        <div className={ css.rightContainer }>
                            {showFavIcon && (
                                <FavouriteCTA
                                    key={ FAVOURITE }
                                    classname={ css.favCTA }
                                    isLoggedIn={ userIsLogged }
                                    flowStep={ flowStep }
                                />
                            )}

                            {showCompareIcon && (
                                <CompareIconCTA
                                    showInHeader={ true }
                                    flowStep={ flowStep }
                                    className={ showFavIcon ? css.margin8 : css.compareIcon }
                                />
                            )}
                            {enableFiltersMenu && !this.isMXCL && <FilterIcon />}
                        </div>
                    </div>
                    <div className={ css.searchInputWrapper }>
                        {enableSearchInput && <SearchInput customClass={ classNames({
                            [css.customOverride]: userIsLogged && this.isNotificationsRevamp
                        }) } />}
                        {enableFiltersMenu && this.isMXCL && <FilterIcon isOlxAutos={ this.isMXCL } />}
                        {
                            userIsLogged && this.isNotificationsRevamp && enableSearchInput
                                ? <Link
                                    key="notifications"
                                    to={ '/notifications' }
                                    className={ classNames(css.notification, {
                                        [css.unReadNotification]: unreadNotifications
                                    }) }
                                    data-aut-id="notifications"
                                >
                                    <NewIconButton
                                        name="notifications"
                                        icon="notifications"
                                        data-aut-id="badge"
                                        active={ false }
                                    />
                                </Link> : null
                        }
                    </div>
                </div>
                <MenuHeader
                    open={ openMenu }
                    user={ Auth.getUser() }
                    onClose={ this.handleToggleMenu }
                    toggleLangModal={ this.handleToggleLangModal }
                    isOlxAutosLandingPage={ this.isMXCL }
                />
                {multiLangFlag
                    && <MultiLangPickerMobile open={ openLangModal } onClose={ this.handleToggleLangModal } />
                }
            </header>
        );
    }
}

const mapStateToProps = state => ({
    preNotificationAvailable: state.preNotification.preNotificationAvailable,
    preNotificationCookie: state.preNotification.preNotificationCookie,
    userIsLogged: !!userIsLogged(state),
    unreadNotifications: unreadNotificationsSelector(state)
});

export default compose(
    withRouter,
    withConfig,
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    withExperiment(CHOOSE_PACKAGE_LAQUESIS_FEATURE_FLAG),
    connect(mapStateToProps, null)
)(SearchHeader);
