import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './ReportModal.scss';
import fetchFactory from 'fetch-ponyfill';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import * as httpHelper from 'Helpers/http';
import { Modal, Loader, Radio } from 'panamera-react-ui';
import Textarea from 'Components/Textarea/Textarea';
import Button from 'Components/Button/Button';
import setToastMessageState from 'Actions/setToastMessageState';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import withLocalStorage from 'HOCs/withLocalStorage/withLocalStorage';
import { BXP_REQUIRED_HEADER } from 'Actions/item';
import { THEMES } from 'Constants/bundles';
import classNames from 'classnames';

const { fetch } = fetchFactory();

export class ReportModal extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        ID: PropTypes.string.isRequired,
        setToastMessageState: PropTypes.func.isRequired,
        track: PropTypes.func.isRequired,
        bannedReasons: PropTypes.object.isRequired,
        intl: PropTypes.object.isRequired,
        localStorage: PropTypes.shape({
            getItem: PropTypes.func.isRequired
        }).isRequired,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        origin: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            reason: '',
            comment: '',
            showLoader: false,
            reasonIsValid: true,
            commentIsValid: true
        };

        this.translations = {
            reportModalTitle: props.type === 'users' ? <Translation id="report_profile_title" /> : <Translation id="report_ad_title" />
        };
        this.theme = props.config.get('theme', 'id');
    }

    handleReason = event => {
        this.setState({
            reason: event.value,
            reasonIsValid: true
        });
    }

    handleComment = comment => {
        this.setState({
            comment,
            commentIsValid: !!comment
        });
    }

    reportAd = () => {
        const param = {};

        if (!this.state.reason || !this.state.comment) {
            this.setState({
                reasonIsValid: !!this.state.reason,
                commentIsValid: !!this.state.comment
            });
            return;
        }

        param.type = this.state.reason;
        param.description = this.state.comment;

        const options = {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...BXP_REQUIRED_HEADER
            }
        };

        if (this.props.type === 'users') {
            this.props.track('trust_tap_report_user', {
                reason: this.state.reason,
                user_affected: this.props.ID,
                origin: this.props.origin
            });
        }
        else {
            this.props.track('trust_tap_report_ad', {
                reason: this.state.reason,
                item_id: this.props.ID
            });
        }

        this.setState({
            showLoader: true
        });

        const deviceFP = this.props.localStorage.getItem('device_fingerprint');

        if (deviceFP) {
            options.headers['x-panamera-fingerprint'] = deviceFP;
        }

        fetch(`/api/${this.props.type}/${this.props.ID}/report`, options)
            .then(response => {
                return response;
            })
            .then(response => {
                this.setState({
                    showLoader: false
                });
                if (httpHelper.isSuccess(response.status)) {
                    this.props.setToastMessageState({
                        isOpen: true,
                        message: <Translation id="report_sent_message" />,
                        expiration: 5000
                    });
                    this.props.onClick();
                }
            }, err => {
                this.props.setToastMessageState({
                    isOpen: true,
                    message: err,
                    expiration: 5000
                });
            });
    }

    render() {
        let content;
        const bannedReasons = this.props.bannedReasons[this.props.type];
        const isLetgo = this.theme === THEMES.LETGO;
        const list = bannedReasons.map((reasons, index) => {
            return (
                <li key={ index }>
                    <Radio
                        id={ reasons.value }
                        name={ reasons.value }
                        value={ reasons.value }
                        checked={ this.state.reason === reasons.value }
                        onClick={ this.handleReason }
                    />
                    <label htmlFor={ reasons.value }>
                        <Translation id={ reasons.key } />
                    </label>
                </li>
            );
        });

        if (this.state.showLoader) {
            content = <Loader className={ css.loader } />;
        }
        else {
            content = (
                <React.Fragment>
                    <div
                        className={ css.reasons }
                        data-aut-id="reasons"
                    >
                        <ul>
                            { list }

                        </ul>
                        { !this.state.reasonIsValid
                        && <span
                            className={ css.error }
                            data-aut-id="reasonError"
                        >
                            <Translation id="report_error_no_reason" />
                        </span>
                        }
                    </div>
                    <Textarea
                        value={ this.state.comment }
                        name="comment"
                        maxRows={ 5 }
                        placeholder={ this.props.intl.formatMessage({ id: 'comment' }) }
                        onChange={ this.handleComment }
                        className={ css.inputComment }
                        errorText={ this.state.commentIsValid ? '' : this.props.intl.formatMessage({ id: 'report_error_no_comment' }) }
                    />
                    <Button
                        type="btnMain"
                        className={ css.submitBtn }
                        onClick={ this.reportAd }
                        data-aut-id="reportAd"
                    >
                        <Translation id="send_complaint" />
                    </Button>
                </React.Fragment>
            );
        }

        return (
            <Modal onClick={ this.props.onClick }
                title={ this.state.showLoader ? '' : this.translations.reportModalTitle }
                className={ classNames(
                    css.reportModal,
                    { [css.reportModalLetgo]: isLetgo }
                ) }>
                { content }
            </Modal>
        );
    }
}

export const mapStateToProps = (state, { config }) => {
    return {
        bannedReasons: config.get('bannedReasons')
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setToastMessageState: state => dispatch(setToastMessageState(state))
    };
};

export default compose(
    withConfig,
    withTrack,
    injectIntl,
    withLocalStorage,
    connect(mapStateToProps, mapDispatchToProps)
)(ReportModal);
