import React from 'react';
import PropTypes from 'prop-types';

import { scrollToTop } from 'Helpers/page';
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import { Link } from 'panamera-react-ui';
import css from './Footer.scss';
import classNames from 'classnames';
import { CARET_ICON } from 'Constants/footer';

export const CollapsableList = ({
    expansionKey,
    expansionTitle,
    expansionClassName,
    fatherStyle,
    childrenStyle,
    listLinks,
    linkTo,
    linkLabel,
    itemKey,
    titleStyle,
    iconStyle,
    iconSize,
    expandedClassName,
    isMxCl,
    icon,
    track
}) => {
    return (
        <ExpansionPanel
            id={ expansionKey }
            title={ expansionTitle }
            containerClassName={ expansionClassName || css.expansionPanel }
            fatherClassName={ fatherStyle || '' }
            childrenClassName={ childrenStyle || '' }
            titleClassName={ titleStyle || '' }
            iconClassName={ iconStyle || '' }
            iconSize={ iconSize }
            expandedClassName={ expandedClassName }
            icon={ icon } >
            <ul className={ classNames(css.links, { [css.mxclLinks]: isMxCl }) }>
                {
                    listLinks
                        .map(element => {
                            function onLinkClick() {
                                track(element, expansionTitle);
                                scrollToTop();
                            }
                            return (<li key={ itemKey(element) }>
                                <Link
                                    to={ linkTo(element) }
                                    onClick={ isMxCl ? onLinkClick : scrollToTop }>
                                    { linkLabel(element) }
                                </Link>
                            </li>);
                        })
                }
            </ul>
        </ExpansionPanel>
    );
};

CollapsableList.propTypes = {
    expansionClassName: PropTypes.string,
    fatherStyle: PropTypes.string,
    childrenStyle: PropTypes.string,
    expansionKey: PropTypes.string.isRequired,
    expansionTitle: PropTypes.string.isRequired,
    listLinks: PropTypes.array.isRequired,
    linkTo: PropTypes.func.isRequired,
    itemKey: PropTypes.func.isRequired,
    linkLabel: PropTypes.func.isRequired,
    titleStyle: PropTypes.string,
    iconStyle: PropTypes.string,
    iconSize: PropTypes.number,
    expandedClassName: PropTypes.string,
    track: PropTypes.func,
    isMxCl: PropTypes.bool,
    icon: PropTypes.oneOf([CARET_ICON.DOWN, CARET_ICON.RIGHT])
};

CollapsableList.defaultProps = {
    isMxCl: false,
    icon: CARET_ICON.DOWN
};

export default CollapsableList;
