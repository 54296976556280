import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from 'panamera-react-ui';
import { FormattedMessage as Translation } from 'react-intl';

import css from './SelectLocation.scss';
import AsyncLocationAutocomplete from './LocationAutocomplete/AsyncLocationAutocomplete';
import NavigationHeader from 'Components/HeaderWrapper/Headers/NavigationHeader';

export class SelectLocation extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        children: PropTypes.node,
        onChange: PropTypes.func.isRequired,
        trackingOrigin: PropTypes.string
    }

    static defaultProps = {
        open: false,
        onClose: () => {},
        trackingOrigin: '',
        children: undefined
    }

    render() {
        const { children, onClose, open, onChange, trackingOrigin } = this.props;
        const locationLabel = <Translation id="locationLabel" />;

        return (
            <React.Fragment>
                { children }
                { open && (
                    <Overlay isOpened={ open }>
                        <div className={ css.overlayLocation }>
                            <NavigationHeader onClickBack={ onClose } title={ locationLabel } classes={ css.uppercase } />
                            <div className={ css.selectLocation }>
                                <AsyncLocationAutocomplete
                                    visible
                                    suggestionsClass={ css.suggestionsClass }
                                    onChange={ onChange }
                                    trackSelectFrom={ trackingOrigin }
                                />
                            </div>
                        </div>
                    </Overlay>
                ) }
            </React.Fragment>
        );
    }
}

export default SelectLocation;
