import React from 'react';
import PropTypes from 'prop-types';
import css from '../Footer.scss';
import classNames from 'classnames';
import { disclaimerData } from '../../../olxautos/constants/disclaimer';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { Link } from 'react-router-dom';
import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import { THEMES } from 'Constants/bundles';

const getContactSection = (sectionName, contactSection, trackContactEvent) => contactSection.map(({ iconName, text, props: { href, tracking } = {}}, i) => (
    <div key={ `${sectionName}-contact-${i}` } className={ css.step }>
        <Icon size={ 24 } icon={ iconName } />
        <div className={ css.iconText } onClick={ trackContactEvent(tracking) }>
            {
                text && text.map((item, index) => {
                    return href ? <a title={ item } key={ `${sectionName}-link-${index}` } href={ href }>{item}<br /></a> : <span key={ `${sectionName}-text-${index}` } >{item}<br /></span>;
                })
            }
        </div>
    </div>
));

export const DisclaimerMobile = ({ config, track }) => {
    const theme = config.get('theme', 'id');

    if (theme === THEMES.LETGO) {
        return null;
    }
    const siteCode = config.get('siteCode');
    const data = disclaimerData[siteCode];
    const { questionSection = {}, disclaimerSection } = data || {};
    const { contactSection = [], secondaryContactSection } = questionSection;
    const showFooterDisclaimer = config.get('showFooterDisclaimer');
    const { heading } = questionSection;

    const shouldBeFooterDisclaimerRendered = showFooterDisclaimer && !!data;

    function trackContactEvent({ event, chosen_option } = {}) {
        return function trackContact() {
            if (event) {
                track(event, { select_from: heading, chosen_option });
            }
        };
    }

    return shouldBeFooterDisclaimerRendered ? (
        <>
            <section className={ css.questionMobile } data-aut-id="disclaimer-mobile-footer">
                {questionSection && <section data-aut-id="question-section">
                    {questionSection.heading && <div className={ css.heading }>{questionSection.heading}</div>}
                    {questionSection.description && <div className={ css.description }>
                        {questionSection.description}
                        {questionSection.link && <Link title={ questionSection.link.text } to={ questionSection.link.url }>
                            {questionSection.link.text}
                        </Link> }
                    </div>}
                </section>}
                <section className={ classNames(css.contactSection) } data-aut-id="primary-contact-section">
                    {getContactSection('primary-contact', contactSection, trackContactEvent)}
                </section>
                { secondaryContactSection ? (
                    <section className={ classNames(css.contactSection) } data-aut-id="secondary-contact-section">
                        {getContactSection('secondary-contact', secondaryContactSection, trackContactEvent)}
                    </section>
                ) : null}
            </section>
            {disclaimerSection && <ExpansionPanel
                id={ 'questionSection' }
                data-aut-id="disclamer-section"
                title={ disclaimerSection.heading }
                containerClassName={ css.expansionPanel }
                icon="arrowDown"
                fatherClassName={ css.titleContainer }
                titleClassName={ css.title }
                iconClassName={ css.icon }
                childrenClassName={ css.links }
                expandedClassName={ css.expanded }
                iconSize={ 16 }
            >
                <div className={ classNames(css.description, css.disclaimerMobile) }>
                    {disclaimerSection.description}
                </div>
            </ExpansionPanel>}
        </>
    ) : null;
};

DisclaimerMobile.propTypes = {
    config: PropTypes.object,
    track: PropTypes.func.isRequired
};

export default withTrack(withConfig(React.memo(DisclaimerMobile)));
