import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage as Translation } from 'react-intl';

import css from '../Footer.scss';
import withConfig from 'HOCs/withConfig/withConfig';
import { Link } from 'panamera-react-ui';

export class Bottom extends React.Component {
    static propTypes = {
        country: PropTypes.object.isRequired,
        brand: PropTypes.string.isRequired,
        footerTitle: PropTypes.string,
        footerAddress: PropTypes.string,
        sections: PropTypes.array,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        })
    };

    static defaultProps = {
        sections: [],
        footerTitle: '',
        footerAddress: ''
    };

    constructor(props) {
        super(props);

        this.translations = {
            footer: values => <Translation id="footer" values={ values } />
        };
        const { config } = this.props;
        const olxAutos = config.get('olxAutos');

        this.isMxCl = !!(olxAutos && olxAutos.isMXCL) || false;
    }

    getFooterText() {
        const { country, brand, footerTitle, footerAddress } = this.props;
        let bottomFooterText = '';
        let copyRightDate = '';

        if (this.isMxCl) {
            copyRightDate = `${(new Date()).getFullYear()} ©`;
            bottomFooterText = (<>{copyRightDate} {footerTitle}</>);
        }
        else {
            copyRightDate = `© 2006-${(new Date()).getFullYear()}`;
            const title = footerTitle || this.translations.footer({ country: country.name });
            const address = footerAddress || brand;

            bottomFooterText = (<>{title}{footerTitle ? '' : '.'} {copyRightDate} {address}</>);
        }
        return bottomFooterText;
    }

    getLinksSection = (sections, separator = ' - ') => {
        const section = sections.find(_section => _section.id === 'other-countries');

        if (!section) {
            return null;
        }

        const links = [];

        section.links
            .forEach((link, index) => links.push(
                separator,
                <Link
                    key={ index }
                    className={ css.links }
                    to={ link.href }
                >
                    { link.title }
                </Link>
            ));

        // It is used to remove first separator
        links.shift();

        return (
            <section className={ `${css.section} ${css.autosSection}` }>
                <div className={ `${css.autosSectionTitle}` }>{ section.title }</div> { links }
            </section>
        );
    };

    render() {
        const { sections } = this.props;

        return (
            <div className={ `${css.bottomFooter} ${css.mobile }` }>
                <div className={ css.sections }>
                    <section className={ css.section }>
                        { this.getFooterText() }
                    </section>
                    { this.olxAutosFooter ? this.getLinksSection(sections, ' • ') : this.getLinksSection(sections)}
                </div>
            </div>
        );
    }
}
export default withConfig(Bottom);
